@use "source/theme" as *;

.root {
  position: relative;
}

.container {
  @include flexColumn();
  padding-bottom: 0;
  padding-top: 0;
}

.text {
  @include flexColumn($alignY: center);
  padding-right: 100px;
  width: 50%;
  @include breakpoint-down(lg) {
    padding-right: 32px;
  }
  @include breakpoint-down(md) {
    padding-right: 0;
    width: 100%;
  }

  button {
    @include flexRow(center, center, nowrap);
    margin-top: 20px;
    line-height: auto;

    svg {
      fill: $white;
      height: 20px;
      width: 20px;
      margin-left: 0;
      margin-right: 8px;
      margin-top: 6px;
    }
  }
}

.banner {
  @include flexRow($alignY: center);
  width: 100%;
  @include breakpoint-down(md) {
    flex-direction: column-reverse;
  }

  img {
    object-fit: cover;
    position: absolute;
    object-position: right;
    right: 0;
    width: 50%;
    height: 100%;
    @include breakpoint-down(md) {
      height: auto;
      position: static;
      width: 100%;
    }
  }
}

.full {
  position: relative;
  width: 100%;
  max-width: unset;
  padding: 0;
  overflow: hidden;

  img {
    max-height: 546px;
    width: 100%;
    object-fit: cover;
    @include breakpoint-down(md) {
      height: 300px;
      object-position: 100%;
    }
  }

  .fullText {
    @include flexColumn();
    left: 0;
    margin: 0 auto;
    max-width: 1300px;
    padding: 0 20px;
    position: absolute;
    right: 0;
    bottom: 40px;
    width: 100%;
    @include breakpoint-down(md) {
      background-color: $warmWhite;
      padding: 20px 16px 40px;
      position: static;
    }
  }
}
