@use "source/theme" as *;

.sidebar {
  @include flexColumn();
  background-color: $white;
  box-shadow: 2px 2px 4px 1px rgb(0 0 0 / 16%);
  left: 0;
  position: absolute;
  top: 49px;
  transform: translateX(-100%);
  visibility: hidden;
  width: 100%;
  z-index: 2;
  @include breakpoint-down(md) {
    visibility: visible;
    max-width: 400px;
  }

  a {
    width: 100%;
  }

  .side:focus {
    outline: none;
  }

  .link {
    @include flexColumn($alignY: center);
    box-shadow: inset 0 1px 0 #e5e5e6;
    height: 56px;
    padding: 0 24px;
    width: 100%;
  }
}

//animation
.slideIn {
  animation: slide-in 0.5s forwards;
}

.slideOut {
  animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
