@use "source/theme" as *;
.alertBackground {
  background-color: $alertBG;
  border-bottom: 1px solid $borderColor;
  width: 100%;
  .alertContainer {
    @include pageLayout();
    @include flexRow(flex-start, center);
    padding-top: 10px;
    padding-bottom: 10px;
    justify-content: space-between;
  }
}

.alert {
  .text {
    @include flexRow(flex-start, center, nowrap);

    padding-inline-end: 16px;
    width: 100%;
    word-break: break-word;
    font-weight: bold;
    font-size: $fontLabel;
    color: $darkBlue;
    a,
    .links {
      color: $link;
      text-decoration: underline;
      span {
        padding: 0 4px;
      }
      font-weight: bold;

      &:hover {
        color: $linkHover;
        text-decoration: none;
      }
    }

    .externalLinkClass {
      svg {
        vertical-align: middle;
        position: relative;
        height: 12px;
        width: 12px;
        fill: none;
        stroke-width: 2;
        stroke: $alertColor;
      }
    }

    .exclamationIcon {
      svg {
        height: 16px;
        padding: 0 4px;
        width: 16px;
        display: flex;
      }
    }
  }
}
