@use "source/theme" as *;

.card {
  @include flexColumn($alignY: space-between);
  background-color: $white;
  border: 1px solid $gray;
  border-radius: 12px;
  margin-bottom: 24px;
  position: relative;
  overflow: hidden;
  width: 100%;

  .icons {
    position: absolute;
    right: 20px;
    margin-top: 20px;
    svg {
      cursor: pointer;
      height: auto;
      fill: $darkBlue;
      margin: 0;
      width: 24px;
      &:hover {
        filter: brightness(2);
      }
    }
  }

  .arrow svg {
    margin-inline-start: 4px;
    width: 16px;
  }

  img,
  .img {
    background-color: $warmWhite;
    min-height: 80px;
    object-fit: cover;
    width: 100%;
  }

  .text {
    @include flexColumn($alignY: space-between);
    height: 100%;
    padding: 28px;

    .header {
      margin: 20px 0;
      &.smaller {
        font-size: $fontBody2;
        margin: 12px 0;
      }
    }

    p {
      font-size: $fontLabel;
    }

    .upper {
      @include flexColumn();

      svg {
        height: 68px;
        width: auto;
      }
    }

    a {
      margin-top: 40px;
    }
  }
}

.side {
  @include breakpoint-up("sm") {
    flex-flow: row-reverse;

    img {
      height: 100%;
      object-fit: cover;
      object-position: 10%;
      width: 36%;
    }
  }

  img {
    object-position: top;
  }
}
