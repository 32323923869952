@use "source/theme" as *;

.root {
  background-color: $white;

  .footer {
    font-size: $fontLabel;
    line-height: 18px;
    padding-bottom: 32px;
    padding-top: 20px;

    div {
      @include flexRow($alignX: space-between);
      width: 100%;
    }

    a {
      color: $darkGray;
      margin-inline-start: 32px;
    }

    @include breakpoint-down(md) {
      p {
        margin: 24px 0;
      }
      a {
        margin-inline-start: 0;
        margin-inline-end: 20px;
      }
    }
  }
}
