@use "source/theme" as *;

.title {
  @include flexColumn();
  margin-bottom: 40px;
  width: 100%;

  p {
    margin-top: 8px;
  }
}

.content {
  @include flexRow($alignX: space-between);
  width: 100%;

  .search {
    @include flexColumn();
    width: 320px;
    @include breakpoint-down(md) {
      width: 280px;
    }
    @include breakpoint-down(sm) {
      margin-bottom: 20px;
      width: 100%;
    }

    h4 {
      font-size: $fontBody2;
      margin-bottom: 8px;
      margin-top: 20px;
    }

    .select {
      cursor: pointer;
      max-width: 300px;
      margin-bottom: 12px;
      @include breakpoint-down(sm) {
        max-width: unset;
      }
    }

    .radio {
      @include flexRow($alignY: center, $wrap: nowrap);
      margin-bottom: 8px;
      margin-inline-start: 12px;

      input {
        cursor: pointer;
      }
      label {
        cursor: pointer;
        margin-inline-start: 8px;
        font-weight: 500;
        margin-bottom: 0;
      }
    }
  }

  .items {
    @include flexRow($alignY: stretch);
    gap: 28px;
    width: calc(100% - 340px);
    @include breakpoint-down(md) {
      width: calc(100% - 300px);
    }
    @include breakpoint-down(sm) {
      width: 100%;
    }
  }

  .card {
    border: 1px solid transparent;
    font-size: $fontBody2;
    font-weight: normal;
    margin-bottom: 0;
    margin-inline-end: 0;
    transition: transform 0.15s ease-in-out;
    width: calc((100% - 56px) / 3);
    @include breakpoint-down(lg) {
      width: calc(50% - 28px);
    }
    @include breakpoint-down(md) {
      width: 100%;
    }

    &:hover {
      border-color: $darkBlue;
    }
  }

  .hide {
    display: none;
  }
}
