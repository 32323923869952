@use "source/theme" as *;

.chatbotHome {
  @include flexColumn();
  background-color: $white;
  margin: 80px auto 32px;
  max-width: 1024px;
  padding: 0 16px;
  width: 100%;

  p {
    margin: 28px 0;
  }

  .option {
    @include flexRow($alignX: space-between, $alignY: stretch);
    overflow: auto;
    width: 100%;
    &::-webkit-scrollbar {
      display: none;
    }

    .item {
      @include flexRow($alignX: space-between);
      background-color: $warmWhite;
      border: 1px solid $gray;
      border-radius: 12px;
      margin-bottom: 16px;
      padding: 24px;
      width: calc(50% - 12px);
      @include breakpoint-down(sm) {
        margin-bottom: 16px;
        width: 100%;
      }

      .text {
        @include flexColumn($alignY: space-between);
        height: 100%;
        width: calc(100% - 60px);
      }

      svg {
        margin-inline-start: 12px;
        width: 48px;
        height: auto;
      }
    }
  }
}
