//Typography
$fontFamily: "Optum Sans", "Arial", sans-serif;
$fontHeading1: 52px;
$fontHeading2: 40px;
$fontHeading3: 32px;
$fontHeading4: 24px;
$fontHeading5: 20px;
$fontBody: 18px;
$fontBody2: 16px;
$fontLabel: 14px;

$lineHeight: 28px;

$gap1: 12px;
$gap2: 24px;

$layerModal: 5;

:root {
  --font-family: "Optum Sans";
}

$h1-font-sizes: (
  null: 60px,
  1024px: 48px,
  768px: 40px,
);

$h2-font-sizes: (
  null: 40px,
  768px: 32px,
);

$h3-font-sizes: (
  null: 32px,
  768px: 24px,
);

$h4-font-sizes: (
  null: 24px,
  768px: 22px,
);

$h5-font-sizes: (
  null: 20px,
  480px: 19px,
);
