@use "source/theme" as *;

.root {
  background-color: $sky;
  height: 100%;

  .content {
    max-width: 1024px;
    width: 100%;
    padding-bottom: 100px;
  }

  svg {
    width: 128px;
  }

  .message {
    margin-bottom: 12px;
    margin-top: 32px;
  }

  .options {
    @include flexRow($alignY: stretch, $alignX: space-between);
    margin-top: 24px;
    width: 100%;
    @include breakpoint-down(sm) {
      flex-direction: column;
    }

    .option {
      @include flexColumn($alignX: space-between);
      background-color: $white;
      border: 1px solid $gray;
      border-radius: 8px;
      width: calc(100% / 2 - 16px);
      padding: 24px;
      height: 100%;
      @include breakpoint-down(sm) {
        height: auto;
        margin-bottom: 24px;
        width: 100%;
      }

      .top {
        @include flexColumn();
        font-size: $fontLabel;
        height: 100%;
      }

      .title {
        @include flexRow($alignY: center, $wrap: nowrap);
        color: $darkBlue;
        font-size: $fontBody2;
        font-weight: 700;
        margin-bottom: 12px;

        svg {
          width: 40px;
          margin-inline-end: 12px;
        }
      }

      ul {
        margin-bottom: 40px;
        margin-top: 12px;

        li {
          margin-bottom: 8px;
        }
      }

      .bottom {
        @include flexColumn();
        width: 100%;
        align-self: flex-end;

        .divider {
          border-bottom: 1px solid $gray;
          margin-bottom: 20px;
          width: 100%;
        }

        .open {
          text-align: right;
          width: 100%;

          svg {
            height: 16px;
            margin-top: -2px;
            width: 16px;
          }
        }
      }
    }
  }

  .help {
    width: 100%;
    text-align: center;
    margin-top: 60px;

    label {
      color: $darkBlue;
      font-weight: 700;
    }
  }
}
