@use "source/theme" as *;

.carousel {
  box-sizing: border-box;
  width: 100%;

  button.pause {
    box-sizing: border-box;
    padding: 0;
    border: none;
    background: transparent;
    cursor: pointer;
    width: 12px;
    margin: 0px 5px;
  }
  button.dot {
    box-sizing: border-box;
    cursor: pointer;
    box-sizing: content-box;
    // background-color: hsl(0deg 0% 100% / 59%);
    background-color: #000000;
    border: 1px solid var(--palette-white);
    opacity: .7;
    // border: #FFF 1px solid;
    // box-shadow: inset 0px 1px 1px #fff;
    // border:none;
    border-radius: 20px;
    height: 8px;
    width: 30px;
    position: relative;
    display: inline-block;
    margin: 0px 5px;
    padding: 0;
  }

  .dot:focus {
    border-radius: 20px;
    box-shadow: 0 0 0 2px var(--palette-dark-blue);
    outline: none;
  }

  .dot.activeDot {
    background-color: var(--palette-white);
    border: 1px solid var(--palette-dark-blue);
  }

  .pauseIcon {
    svg {
      height: 9px;
    }        
  }
  .pauseIcon.onPause {
    svg {
      opacity: 1;
      fill: var(--palette-primary);
    }    
  }
  .pauseIcon.offPause {
    svg {
      opacity: .7;
      // fill: var(--palette-light);
      fill:  var(--palette-white);
    }    
  }
}
