@use "source/theme" as *;

.noConvo {
  @include flexColumn(center, center);
  width: 100%;
  height: 100%;

  svg {
    height: auto;
    width: 80px;
    margin-bottom: 12px;
  }

  p {
    margin-top: 8px;
    margin-bottom: 32px;
  }
}
