@use "source/theme" as *;

$success: #227000;

.content {
  border: 1px solid $darkBlue;
  gap: $gap2;
  padding: 52px 32px;
  width: 100%;
  max-width: 768px;
  line-height: $lineHeight;
  margin: auto 0;
}
.heading {
  margin-top: -24px;
  padding-bottom: $gap1;
  margin-bottom: 0px;
  svg {
    visibility: hidden;
    max-height: 30px;
  }
  border-bottom: 1px solid $borderColor;
}
.modalTitle {
  // font-size: $fontHeading3;
  font-weight: bold;
  color: $darkBlue;
  margin-bottom: $gap1;
}

.formTitle {
  font-size: $fontHeading4;
  font-weight: bold;
  color: $darkBlue;
}

.formSubtitle {
  font-size: $fontBody2;
  font-weight: bold;
  color: $darkGray;
  .formComment {
    font-size: $fontLabel;
    color: $disable;
    font-weight: normal;
    display: block;
  }
}

.formText {
  font-size: $fontLabel;
  color: $darkGray;
}
.formLink {
  cursor: pointer;
  color: $link;
  font-weight: normal;

  &:hover {
    color: $linkHover;
  }
  text-decoration: underline;
}
.formContainer {
  .formItem {
    display: flex;
    flex-direction: column;
    gap: 6px;
    .formTitle {
      small {
        font-size: $fontLabel;
        color: $disable;
        font-weight: normal;
        // margin start

        margin-inline-start: $gap1;
      }
    }
  }

  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $gap1;
}
.divider {
  // change color of hr
  border-top: 1px solid $borderColor2;
  width: 100%;
}
.stepButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: $gap2;
  font-size: $fontLabel;
  .backButton {
    &::before {
      // < symbol
      content: "\003C";
    }
  }
  .backButton,
  .closeButton {
    cursor: pointer;
    color: $link;
    font-weight: bold;
    font-size: $fontBody;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: $gap1;
    &:hover {
      color: $linkHover;
    }
  }
  .continueButton {
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      margin-bottom: 0px;
      font-size: $fontBody;
      padding: $gap1 $gap2;
      border-radius: $gap2 * 2;
      min-width: 160px;
    }
  }
}

// ================= Step 1 =================
.s1Modal {
}
.s1 {
  width: 100%;
  max-width: 576px;
  display: flex;
  flex-direction: column;
  gap: $gap2;

  .buttonsContainer {
    display: flex;
    flex-direction: column;
    gap: $gap1;

    .buttonBox {
      // border: 1px solid $borderColor;
      cursor: pointer;
      background-color: $warmWhite;
      padding: $gap2;
      border-radius: 16px;
    }
  }
}

// ================= Step 2 =================
.s2Modal {
}
.s2 {
  width: 100%;
  max-width: 576px;
  display: flex;
  flex-direction: column;
  gap: $gap2;
}

.helpContent {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: $gap2;
}

// ================= Step 3 =================
.s3Modal {
}
.s3 {
  width: 100%;
  max-width: 576px;
  display: flex;
  flex-direction: column;
  gap: $gap2;
}

// ================= Step 4 =================
.s4Modal {
}
.s4 {
  width: 100%;
  max-width: 576px;
  display: flex;
  flex-direction: column;
  gap: $gap2;
  .buttonBox {
    // border: 1px solid $borderColor;
    background-color: $warmWhite;
    padding: $gap2;
    border-radius: 16px;
    display: flex;
    gap: $gap2;
    .icon {
      width: 40px;
      height: 40px;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: $optumOrange;
      color: $white;
    }
  }
}

// ================= Step 5 =================
.s5Modal {
}
.s5 {
  width: 100%;
  max-width: 576px;
  display: flex;
  flex-direction: column;
  gap: $gap2;
}

// ================= Step 6 =================
.s6Modal {
}
.s6 {
  width: 100%;
  max-width: 576px;
  display: flex;
  flex-direction: column;
  gap: $gap2;
}

// ================= Step 7 =================
.s7Modal {
}
.s7 {
  width: 100%;
  max-width: 576px;
  display: flex;
  flex-direction: column;
  gap: $gap2;
}

// ================= Step 8 =================
.s8Modal {
}
.s8 {
  width: 100%;
  max-width: 576px;
  display: flex;
  flex-direction: column;
  gap: $gap2;
  .buttonBox {
    // border: 1px solid $borderColor;
    cursor: pointer;
    background-color: $warmWhite;
    padding: $gap2;
    border-radius: 16px;
    display: flex;
    gap: $gap2;
  }
}

// ================= Step 9 =================
.s9Modal {
  max-width: 1200px;
}
.s9 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $gap2;
  .timesContainer {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    border: 1px solid $borderColor;
    border-radius: 4px;
    .dayContainer {
      text-align: center;
      .dayHeader {
        padding: $gap1;
        .dayName {
        }
        .date {
          font-size: $fontBody;
          color: $darkBlue;
          font-weight: bold;
        }
        border-bottom: 2px solid $borderColor2;
      }
      // even background color
      &:nth-child(odd) {
        background-color: $warmWhite;
        .dayHeader {
          border-bottom: 2px solid $darkBlue;
        }
      }

      .slotsContainer {
        display: flex;
        flex-direction: column;
        gap: $gap1;
        padding: $gap1;
        .slot {
          @include buttonWhite($color: $link, $hoverColor: $linkHover);
          min-width: unset;
          min-height: unset;

          cursor: pointer;
          &:hover {
            background-color: $linkHover;
            color: $white;
          }
          &.selected {
            background-color: $darkBlue;
            color: $white;
          }
        }
      }
    }
  }
  .noneWork {
    cursor: pointer;
    color: $link;
    font-weight: bold;
    font-size: $fontBody;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: underline;
  }
}

// ================= Step 10 =================
.s10Modal {
}
.s10 {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: $gap2;
  .buttonBox {
    // border: 1px solid $borderColor;
    background-color: $warmWhite;
    padding: $gap2;
    border-radius: 16px;
    display: flex;
    gap: $gap2 * 2;
    .icon {
      width: 80px;
      height: 80px;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: $success;
      color: $white;

      svg {
        height: 40px;
        width: 40px;
        fill: white;
      }
    }

    .scheduledTime {
      font-size: $fontBody;
      color: $darkBlue;
      font-weight: bold;
    }
  }
}
