@use "source/theme" as *;

.root {
  font-size: 16px;
  line-height: 20px;
  height: 36px;
  cursor: pointer;
}
.no_value {
  color: $disable;

  option {
    color: $mainText;
  }
}
