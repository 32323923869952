@use "source/theme" as *;

.content {
  border: 1px solid $darkBlue;
  gap: 20px;
  padding: 52px 32px;
  top: 15% !important;
  width: 100%;
  text-align: left;
  align-items: flex-start;
  margin-top: 0;
  margin-bottom: 200px;

  label {
    border-bottom: 1px solid $gray;
    font-weight: 700;
    padding-bottom: 20px;
    width: 100%;
  }

  li {
    cursor: pointer;
    font-weight: 700;
    &:hover {
      filter: brightness(0.5);
    }
  }
}
