@use "source/theme" as *;

.content {
  align-items: center;
}

.modal {
  gap: 20px;
  max-width: 600px;
  padding-top: 48px;
  width: 100%;
}

.cards {
  @include flexRow($alignX: space-around, $alignY: stretch);
  width: 100%;
  margin-top: 20px;
  @include breakpoint-down(sm) {
    flex-direction: column;
  }

  .left {
    position: relative;
    max-width: 408px;
    width: 50%;
    margin-bottom: 72px;
    margin-top: 72px;
    @include breakpoint-down(sm) {
      background-color: $white;
      height: auto;
      margin-bottom: 0;
      margin-top: 0;
      max-width: unset;
      padding-bottom: 20px;
      padding-top: 20px;
      position: sticky;
      z-index: 2;
      top: 0;
      width: 100%;
    }

    .img {
      position: sticky;
      overflow: hidden;
      max-height: 408px;
      height: 100%;
      top: calc((100% - 408px) / 2 + 40px);
      margin-inline-end: 16px;
      @include breakpoint-down(sm) {
        height: 280px;
        margin-inline-end: unset;
        top: 20px;
      }
    }

    img {
      position: absolute;
      transition: all 0.1s ease-in-out;
      border-radius: 16px;
      max-width: 408px;
      width: 100%;
      height: auto;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      @include breakpoint-down(sm) {
        max-width: unset;
        height: 100%;
        object-fit: cover;
        object-position: top;
        width: 100%;
      }
    }

    .inactive {
      transition: opacity 0.5s ease-in-out;
      opacity: 0;
    }

    .active {
      opacity: 1;
    }
  }

  .right {
    position: relative;
    width: 50%;
    @include flexColumn();
    max-width: 516px;
    @include breakpoint-down(sm) {
      width: 100%;
      max-width: unset;
    }
  }

  .text {
    @include flexColumn();
    margin: 72px auto;
    @include breakpoint-down(sm) {
      margin: 40px 0;
    }

    label {
      color: $darkBlue;
      font-size: $fontLabel;
      font-weight: 700;
      margin-bottom: 8px;
    }

    .body {
      line-height: 28px;
      margin-bottom: 32px;
      margin-top: 20px;
      white-space: pre-line;
    }
  }
}
