@use "source/theme" as *;

.root {
  @include flexColumn(center, center);
  width: 100%;
}

.content {
  @include flexColumn();
  margin: 0 auto;
  max-width: 1300px;
  padding: 64px 16px;
  width: 100%;
  @include breakpoint-down(sm) {
    padding: 40px 16px;
  }
}
