@use "source/theme" as *;

.container {
  @include flexRow(flex-start, center, nowrap);
  // background-color: $lightGray;
  direction: ltr;
  position: relative;
  &:focus-within {
    background-color: $gray;
  }

  select {
    cursor: pointer;
    appearance: none;
    background-color: transparent;
    border: none;
    // font-family: $fontFamily;
    font-size: $fontLabel;
    height: 22px;
    outline: none;
    padding: 0 20px 0 0px;
    min-width: unset;
    font-family: inherit;
    font-weight: inherit;
    box-sizing: content-box;
    color: inherit;
    padding-left: $gap1;
    margin-left: -1 * $gap1;
    &:hover {
      color: $linkHover;
    }

    &::-ms-expand {
      display: none;
    }

    &:focus {
      outline: 3px solid var(--palette-aria-focus-dark)
    }
  }

  svg {
    fill: $link;
    pointer-events: none;
    position: absolute;
    right: 7px;
    height: 20px;
    top: -5;
    width: 20px;
  }

  .hiddenSelect {
    height: 0px;
    overflow: hidden;
    position: relative;
    left: 0;
    bottom: 0;
  }
}


