@use "source/theme" as *;

$bannerHeight: 296px;
$bannerHeightHome: 570px;

.bannerContainer {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}


.banner :global {
  height: $bannerHeight;
  // @media screen and (max-width: 1440px) {
  //   height: 212px;
  // }

  background: var(--palette-secondary);
  @media screen and (max-width: 550px) {
    height: fit-content;
  }

  .slick-slide {
    pointer-events: none;

    /* 
      its a hack to fix the delay issue of react-slick package
      issue was: 
        if we set speed to 500, it will accumulate delay when fast hover. then become more and more delay
        if we set speed to 0 when fast hover, hover is good but it also removes the transition effect
      solution:
        force the transition to be 0.5s, so it will always be 0.5s even if speed is 0
    */
    transition: opacity 500ms linear 0s, visibility 500ms linear 0s !important;

    @media screen and (max-width: 550px) {
      // height: fit-content;
    }
  }

  .slick-current {
    pointer-events: auto !important;
  }

  .slick-list {
    height: $bannerHeight;
    // @media screen and (max-width: 1440px) {
    //   height: 212px;
    // }
    @media screen and (max-width: 550px) {
      height: fit-content;
    }
  }

  .slick-dots {
    @include pageCenter();
    position: static;
    text-align: right;
    transform: translateY(-30px);

    // @media screen and (max-width: 1440px) {
    //   transform: translateY(-100px);
    // }

    @media screen and (max-width: 550px) {
      background: var(--palette-primary);
      margin: auto;
      padding: 16px 10px;
      box-sizing: border-box;
      // position: relative;
      transform: unset;
      box-sizing: border-box;
    }

    li {
      margin-bottom: 1px;
      margin-top: 1px;
      background-color: $darkGray;
      opacity: 0.4;
      border-radius: 20px;
      height: 10px;
      width: 32px;
    }

    .slick-active {
      opacity: 1;
    }

    box-sizing: border-box;
    // margin-top: -16px;
    // padding-right: 25px;
  }
}

.root {
  @include flex();
  background-color: $gray;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;

  justify-content: center;
  align-items: center;
  height: $bannerHeight;
  transition: background-image 0.2s ease-in-out;
  width: 100%;
  // @media screen and (max-width: 1440px) {
  //   height: 212px;
  // }
}

.withContent {
  @media screen and (max-width: 550px) {
    background: #00797a !important;
    height: auto;
  }
}


.bannerHome :global{
  @include breakpoint-up(lg) {
    height: $bannerHeightHome;
    .slick-list {
      height: $bannerHeightHome;
    }
  }
}

.rootHome {
  @include breakpoint-up(lg) {
    justify-content: unset;
    height: $bannerHeightHome;
    // margin-top: 10%;

    .content {
      position:absolute;
      top:25%;
    }
  }
}

.contentContainer {
  position: absolute;
  bottom: 32px;
  @include pageCenter();
  .contentCardList {
    display: grid;
    // 4 cars per row
    grid-template-columns: repeat(4, 1fr);
    gap: $gap1;
  }
}
