@use "source/theme" as *;

a,
.linkButton {
  color: $link;
  cursor: pointer;
  font-weight: 900;
  text-decoration: none;
  &:hover,
  &:focus-visible {
    color: $linkHover;
    svg {
      fill: $linkHover;
    }
  }

  svg {
    fill: $link;
    height: 10px;
    margin-left: 4px;
  }
}

.chip {
  border: 1px solid $darkBlue;
  border-radius: 20px;
  color: $darkBlue;
  font-size: $fontLabel;
  height: 28px;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  width: auto;

  .bg {
    background-color: $darkBlue;
    height: 28px;
    opacity: 0.15;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
  }
}

.button {
  position: relative;
  background-color: $buttonBGColor;
  border-radius: 24px;
  border: 2px solid transparent;
  color: $buttonColor;
  cursor: pointer;
  min-height: 44px;
  font-family: $fontFamily;
  font-size: $fontBody2;
  font-weight: 700;
  margin-bottom: 8px;
  min-width: 120px;
  outline: none;
  padding: 4px 16px 6px;
  transition: all ease-in-out 0.2s;
  // white-space: nowrap;

  &:hover {
    background-color: $buttonHover;
  }
  &:focus {
    outline: 3px solid $buttonHover !important;
    outline-offset: 2px;
  }

  &:disabled {
    background-color: $darkGray;
    cursor: default;
  }

  &.light {
    background-color: $white;
    color: $darkBlue;
    border-color: $darkBlue;
    &:hover {
      color: $link;
      border-color: $link;
    }

    &:focus {
      outline: 3px solid $link !important;
      outline-offset: 2px;
    }
  }
  @include breakpoint-down(sm) {
    width: 100%;
  }
}

.scrollTop {
  @include flexColumn(center, center);
  background-color: $darkGray;
  border-radius: 50%;
  bottom: 60px;
  cursor: pointer;
  height: 40px;
  opacity: 0;
  position: fixed;
  right: 100px;
  transition: opacity 0.4s ease-in-out;
  visibility: hidden;
  width: 40px;
  z-index: 5;
  @media screen and (max-width: 650px) {
    right: 40px;
  }

  svg {
    height: auto;
    padding-top: 4px;
    transform: rotate(180deg);
    width: 40px;
    path {
      fill: $warmWhite;
    }
  }

  &:hover {
    transform: scale(1.1);
  }
  &.visible {
    opacity: 1;
    visibility: visible;
  }
}
