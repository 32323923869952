@use "source/theme" as *;

.content.active {
  border: 4px solid $darkBlue;
  // firm shadow to emulate thickness
  box-shadow: 6px 6px 0 -2px $darkBlue;  


  .main {
    .text {
      color: $link;
    }
  }
}

.content {
  box-sizing: border-box;
  padding: 16px 12px;
  
  margin:unset;
  width: 100%;
  background-color: $white;

  border-radius: 16px;
  border: 4px solid transparent; /* Transparent border */
  box-shadow: 6px 6px 0 -2px transparent; /* Transparent shadow */
  
  .main {
    @include flexColumn();
    text-align: left;
    align-self: center;
    justify-content: space-between;
    height: 100%;

    background-color: $white;
    
    .text {
      margin-bottom: 24px;
      white-space: pre-line;
      line-height: 1.4rem;
      b {
        display: block;
        margin-bottom: 8px;
      }
    }

    @include breakpoint-up(lg) {
      padding: 8px;
    }
    
    .button {
      cursor: pointer;
      font-weight: 900;
      color: $link;
      &:hover {
        color: $linkHover;
        text-decoration: underline;
      }
    }

  }
  a {
    color: $link;
    &:hover {
      color: $linkHover;
      text-decoration: underline;
    }
  }
}