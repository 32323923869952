@use "source/theme" as *;

.root {
  @include flexColumn();
  background-color: $warmWhite;
  width: 100%;
}

.questions {
  @include flexColumn();
  margin-top: 40px;
  overflow: hidden;
  width: 100%;

  .item {
    @include flexColumn($alignY: space-between);
    background-color: $white;
    border: $lightGray solid 1px;
    border-top: unset;
    padding: 20px 32px;
    width: 100%;
    &:first-child {
      border-radius: 12px 12px 0 0;
      border-top: $lightGray solid 1px;
    }
    &:last-child {
      border-radius: 0 0 12px 12px;
    }
  }
}

.collapse {
  @include flexRow(space-between, center, nowrap);
  color: $darkBlue;
  font-weight: 700;
  cursor: pointer;
  width: 100%;
  svg {
    transition: all 0.2s ease-in-out;
    fill: $darkBlue;
    width: 16px;
    height: 16px;
    margin-inline-start: 12px;
  }
}

.topactive svg {
  transform: rotate(90deg);
}

.collapsible {
  max-height: 0;
  overflow: hidden;
  transition: all 0.1s ease-in-out;
}

.active {
  margin-top: 16px;
  max-height: 1380px;
}
