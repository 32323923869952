@use "source/theme" as *;

.content {
  @include flexRow($alignY: center);
  padding-top: 20px;
  @media screen and (max-width: 768px) {
    display: none;
  }

  a {
    margin-bottom: -1px;
    border-bottom: 2px solid transparent;
    color: $darkGray;
    padding: 0 10px 16px;
    &:hover {
      color: $darkBlue;
      border-color: $darkBlue;
    }
  }

  .active a {
    color: $darkBlue;
    border-color: $darkBlue;
  }
}

.link {
  @include flexRow(center, center, nowrap);
  font-size: $fontLabel;
  line-height: 18px;
  margin-right: 20px;
  white-space: pre-line;
}
