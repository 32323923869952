@use "source/theme" as *;

.input {
  div {
    display: none;
  }
}

.error {
  input {
    border: 2px solid $error;
    box-sizing:border-box
  }
  div {
    display: flex;
    color: $error;
    font-size: 1rem;
    margin-top: 10px;
    text-align: left;
    align-items: center;


    svg {
      fill: $error;
      height: 28px;
      width: 28px;
      margin-right: 5px;
    }
  }

}