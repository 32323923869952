@use "source/theme" as *;

.question_wrapper {
  // padding: 0 40px;
}

.question_header {
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 15px;
  color: $darkBlue;
}
.question_items {
}
.question_item_wrapper {
  width: 100%;
  &:not(:last-child) {
    border-bottom: 1px solid #bfbfbf;
  }
}
.question_item {
  padding: 15px 0;
  // border-bottom: solid 1px lightgrey;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  color: $darkBlue;
  cursor: pointer;
  text-align: left;

  .svgWrapper {
    padding-right: 15px;
  }
  svg {
    height: 14px;
    transition: transform 0.1s ease-out;
  }

  &.question_expand {
    svg {
      transform: rotate(90deg);
      transition: transform 0.1s ease-out;
    }
  }
}

// animation for expanding answer_item
.answer_item {
  color: $darkGray;
  font-size: 0.9rem;
  font-weight: 500;
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
  border: none;
  box-sizing: border-box;
  font-size: 0;
  line-height: 0;
  padding: 0px;

  &.answer_expand {
    border: none;
    box-sizing: border-box;
    font-size: 0.9rem;
    line-height: unset;
    max-height: 500px;
    transition: max-height 0.5s ease-out;
    text-align: left;
    padding: 5px 0px 20px 0px;
  }
}
