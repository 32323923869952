@use "source/theme" as *;

$success : #227000;

.content {
  border: 1px solid $darkBlue;
  gap: $gap2 * 2;
  padding: 52px 32px;
  width: 100%;
  max-width: 1200px;

  line-height: $lineHeight;
  margin: auto 0;
}
 
.stepIconsContainer {
  width: 100%;
  border-top: 1px solid $borderColor;
  display: flex;
  justify-content: center;
  padding-top: $gap2;

  .stepIcons {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: $gap2;
    position: relative;

    .stepOneIconContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 160px;

      .stepIconNumber {
        font-size: $fontHeading5;
        font-weight: bold;
        width: 66px;
        height: 66px;
        border-radius: 50%;
        background-color: $white;
        border: 3px solid $disable;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          width: 80%;
          height: 80%;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid transparent;
          color: $disable;
          svg {
            fill: white;
            transform: translateY(2px);
          }
        }
      }

      .stepIconText {
        font-size: $fontHeading5;
        font-weight: normal;
        margin-top: $gap1;
      }

      &.stepIconActive {
        .stepIconNumber {
          border: 3px solid $success;
          span {
            border: 1px solid $success;

            color: $success;
          }
        }
        .stepIconText {
          
          font-weight: bold;
        }
      }

      &.stepIconFinished {
        .stepIconNumber {
          border: 3px solid $success;
          background-color: $success;
        }
        .stepIconText {
          font-weight: normal;
        }
      }
    }

    .horizontalLine {
      border-top: 2px solid $disable;
      top: 10px;
      width: 160px;
      margin-left: -70px;
      margin-right: -70px;
      margin-top: 33px;
      z-index: -1;

      // hide last element
      &:last-child {
        display: none;
      }
    }
  }
}


.formTitle {
  font-size: $fontHeading4;
  font-weight: bold;
  color: $darkBlue;
}

.formContainer {
  .formItem {
    display: flex;
    flex-direction: column;
    gap: $gap1;
    .formTitle {

      small {
        font-size: $fontLabel;
        color: $disable;
        font-weight: normal;
        // margin start
  
        margin-inline-start: $gap1;
      }
    }
  }

  .formSubRow {
    display: flex;
    gap: $gap2;
    .formItem {
      flex: 1;
      gap: 6px;
      .formSubtitle {
        font-size: $fontLabel;
        font-weight: bold;
        color: $darkGray;
      }
    }
  }

  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $gap1;
}
.divider {
  // change color of hr
  border-top: 1px solid $borderColor2;
  width: 100%;
}
.stepButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: $gap2;
  font-size: $fontLabel;
  .backButton {
    &::before {
      // < symbol
      content: "\003C";
    }    
  }
  .backButton, .closeButton {
    cursor: pointer;
    color: $link;
    font-weight: bold;
    font-size: $fontBody;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: $gap1;
    &:hover {
      color: $linkHover;
    }
  }
  .continueButton {
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      margin-bottom: 0px;
      font-size: $fontBody;
      padding: $gap1 $gap2;
      border-radius: $gap2 * 2;
      min-width: 160px;
    }
  }
}

// ================= Step 1 =================
.s1 {
  width: 100%;
  max-width: 768px;
  display: flex;
  flex-direction: column;
  gap: $gap2;
  align-items: center;
  .toptext {
    width: 500px;
    font-weight: normal;
    color: $mainText;
    margin-bottom: $gap2;
    text-align: center;
  }
  .formContainer {
    gap: $gap2;
  }
}

// ================= Step 2 =================
.s2 {
  width: 100%;
  max-width: 768px;
  display: flex;
  flex-direction: column;
  gap: $gap2;
  align-items: center;

}



// ================= Step 3 =================
.s3 {
  width: 100%;
  max-width: 768px;
  display: flex;
  flex-direction: column;
  gap: $gap2;
  align-items: center;


  .review {
    position: relative;
    border: 1px solid $borderColor;
    width: 100%;
    min-height: 300px;
    padding: $gap2;

    display: flex;
    flex-direction: column;
    gap: $gap2;

    .reviewEditButton {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      right: $gap2;
      top: $gap2;
      line-height: $gap1;
      color: $link;
      font-weight: bold;
      &:hover {
        color: $linkHover;
      }
    }

    .reviewItem {
      display: flex;
      flex-direction: column;
      gap: 6px;
      
      .value {
        font-weight: bold;
      }
    }
  }
}

// ================= Step 4 =================
.s4 {
  width: 100%;
  max-width: 768px;
  .resultContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $gap2;

    .title {
      font-size: $fontHeading3;
      font-weight: bold;
      color: $darkBlue;
    }
    .divider {

    }
    .downloadButton {
      button {
        display: flex;
        align-items: center;
        gap: $gap1;
        background-color: $link;
        border-radius: 8px;
        padding: $gap1 $gap2;
        font-size: $fontBody;

        svg {
          width: 30px;
          height: 30px;
        }
      }
    }
    .bulletItems {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: $gap2 * 2;
      .bulletItem {
        display: flex;
        align-items: center;
        gap: $gap1;
        svg {
          height: 16px;
          fill:#227000
        }
        span {
          line-height: 0px;
        }
        small {
          display: block;
        }
      }
    }

    .findProviderButtonContainer {
      padding-top: $gap2;
      padding-bottom: $gap2;
      .button {
        background-color: $warmWhite;
        color: $darkBlue;
        margin-bottom: 0px;
        font-size: $fontBody;
        padding: $gap1 $gap2;
        border-radius: $gap2 * 2;
        min-width: 160px;

        display: flex;
        align-items: center;
        gap: $gap1;

        svg {
          height: 24px;
          width: 24px;
        }

        &:hover {
          background-color: $lightGray;
        }
      }
    }
  }

  .stepDescription {
    max-width: 620px;
    width: 100%;

    p {
      margin-top: $gap1;
      display: block;
    }
  }

}