@use "source/theme" as *;

.root {
  background-color: $benefitsBG;
}

.content {
  align-items: center;
}

.cards {
  @include flexRow($alignY: stretch);
  gap: 24px;
  margin: 40px 0 20px;
  width: 100%;

  @include breakpoint-down(md) {
    @include flexColumn(stretch, flex-start);
    gap: 0;
  }

  .left,
  .right {
    width: calc(50% - 12px);
    @include breakpoint-down(md) {
      width: 100%;
    }
  }

  .right {
    @include flexColumn();
  }
}
