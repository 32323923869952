@font-face {
  font-family: "Optum Sans";
  font-weight: 500;
  font-display: swap;
  src: local("myfont"), url("../assets/OptumSans-Regular.woff2") format("woff");
}

@font-face {
  font-family: "Optum Sans";
  font-weight: 900;
  font-display: swap;
  src: local("myfont"), url("../assets/OptumSans-Bold.woff2") format("woff");
}

//App Shell
.app {
  background-color: white;
  display: flex;
  /* 
    note: to fix when showing modal, bg flicks, so we move max-width from body to .app
    otherwise scroll bar will appear beside narrow body, not becides the browser
  */
  max-width: 1440px;
  margin: 0 auto;
  cursor: default;
}

html {
  scroll-behavior: smooth;
}

//Body
body {
  color: #4b4d4f;
  padding: 0;
  font-family: "Optum Sans", "Arial", sans-serif !important;
  font-size: 16px;
  line-height: 22px;
  min-width: 320px;
  background-color: #efefee;
  @media screen and (min-width: 1440px) {
    box-shadow: 1px 0 #d0d0ce, -1px 0 #d0d0ce;
  }
  @media screen and (max-width: 768px) {
    padding-top: 48px;
  }
}

//Global classes
h1,
h2,
h3,
p {
  margin: 0;
}

p {
  // color: #5a5a5a;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: #0c55b8;

  &:focus-visible {
    outline: 1px rgba(black, 0.4) dashed;
  }
}

img {
  flex-shrink: 0;
}

input::placeholder {
  color: #999 !important; // same as --palette-disable
}
