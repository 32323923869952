@use "source/theme" as *;

.cards {
  @include flexRow($alignX: space-between, $alignY: stretch);
  width: 100%;
  @include breakpoint-down(sm) {
    @include flexColumn(stretch, flex-start);
  }

  .item {
    @include flexColumn($alignY: space-between);
    background-color: $white;
    border-radius: 16px;
    border: 3px solid transparent;
    padding: 24px;
    width: calc(100% / 4 - 16px);
    @include breakpoint-down(md) {
      width: calc(100% / 2 - 8px);
      margin-bottom: 20px;
    }
    @include breakpoint-down(sm) {
      width: 100%;
    }

    p {
      margin-bottom: 24px;
    }

    &:hover,
    &:active {
      border-color: $darkBlue;
      box-shadow: 4px 4px $darkBlue;
    }
  }
}
