@use "source/theme" as *;

.register_box {
  padding-bottom: 0;
}

.welcome_label {
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 1.2rem;
  color: $darkBlue;
}
.nickname_label {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 1rem;
  color: $darkBlue;
}

.register_header {
  // padding: 0 0 30px 0;
  text-align: center;

  h2 {
    margin-bottom: 10px;
    font-weight: 700;
  }
}

.register_form {
  // color: $darkBlue;
  padding: 20px 30px 30px 30px;
  // background-color: $optumSecondary;
  // border-top: solid 1px #ededed;

  background-color: $inputBG;
  border-top: solid 1px #e0e0e0;
  border-radius: 0 0 8px 8px;
  text-align: center;
  // padding: 30px;
}

.register_input {
  max-width: 340px;
  margin: 25px auto;
  font-size: 14px;
  text-align: left;
}

.description {
  font-size: 16px;
  margin-top: 10px;
}

.nickname_input {
  position: relative;
}

.nickname_wrapper {
  position: relative;
}

.generate_nick_wrapper {
  position: absolute;
  right: 10px;
  top: 6px;
  display: flex;
  gap: 8px;

  a,
  span {
    cursor: pointer;
    svg {
      height: 1.2rem;
      margin-right: 5px;
      fill: $mainText;
    }
    &:hover {
      svg {
        fill: $linkHover;
      }
    }
  }
}

.register_button_wrapper {
  display: flex;
  justify-content: center;
}

.login_wrapper {
  padding: 20px 0;
  text-align: center;
}

.back_to_login {
  font-weight: 700;
}

@include breakpoint-up(md) {
  .register_input {
    font-size: 16px;
  }

  .generate_nick_wrapper {
    svg {
      height: 16px;
    }
  }
}
