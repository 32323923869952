@use "source/theme" as *;

.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: $layerModal;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
}

.space {
  transition: all 0.1s linear;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  width: 100%;
  height: 100%;
  position: absolute;
}

.content_container {
  @include flexColumn($alignX: center);
  position: relative;
  overflow-y: auto;
  width: 100%;
  height: 100%;
}

.content {
  @include flexColumn(center, center);
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  height: auto;
  margin: 60px 0;
  max-width: 550px;
  padding: 24px;
  width: auto;
  z-index: $layerModal;
  position: relative;

  @include breakpoint-down(sm) {
    border-radius: 0;
    width: 100%;
  }
}

.close {
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 12px;
  cursor: pointer;
  z-index: $layerModal + 1;
  svg {
    height: 46px;
    transition: fill ease-in-out 0.2s;
    fill: lightgray;
  }

  &:hover {
    svg {
      transition: fill ease-in-out 0.2s;
      fill: $linkHover;
    }
  }
}

.mobile_close {
  @include breakpoint-down(sm) {
    display: none;
  }
}
