@use "source/theme" as *;

.chatForm {
  @include flexColumn();
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.1);
  font-family: $fontFamily;
  padding: 20px;
  width: 100%;

  p {
    margin-bottom: 24px;
  }

  .field {
    @include flexColumn();
    margin-bottom: 16px;
    width: 100%;

    label {
      font-weight: 700;
    }

    .input {
      width: 100%;
    }

    .format {
      font-size: 12px;
      padding: 0;
    }
  }
}

.chatAnswers {
  @include flexColumn();
  margin-bottom: 12px;
  padding: 12px 12px 0px;
  width: 100%;

  .item {
    margin-top: 12px;
    label {
      font-weight: 700;
    }
  }
}

.modal {
  margin-left: auto;
  margin-right: 32px;
  width: 100%;
  max-width: 375px;
}

.content {
  display: flex;
  padding: 0;
  width: 100%;
  height: 100%;
  max-height: 96vh;
  box-shadow: none;
  margin: auto 0 0;

  .top {
    @include flexRow(center, space-between, nowrap);
    background-color: $white;
    border-bottom: $gray 1px solid;
    width: 100%;
    border-radius: 8px 8px 0 0;
    padding: 12px 16px;

    .right {
      cursor: pointer;
      svg {
        width: 16px;
      }
    }
  }
}

.chatbot {
  height: 100%;
  width: 375px;
  ::-webkit-scrollbar {
    display: none;
  }

  :global {
    .lineMessage {
      text-align: center;
      background-color: transparent;
      color: $mainText;
      width: 100%;
      font-size: 12px;
      display: flex;
      flex-direction: row;

      &:before,
      &:after {
        content: "";
        flex: 1 1;
        border-bottom: 1px solid;
        margin: auto;
      }
      &:before {
        margin-right: 10px;
      }
      &:after {
        margin-left: 10px;
      }
    }

    .rsc-container {
      background-color: var(--palette-white);
      box-sizing: border-box;
      box-shadow: none;
      height: 100%;
      width: 100%;
    }

    .rsc-content {
      display: flex;
      flex-flow: column nowrap;
      height: calc(100% - 55px);
      margin-top: 0;
      padding-top: 0;
      overflow-y: auto;
      width: 100%;
      min-height: 300px;
      margin-top: 0;
    }

    .rsc-header {
      background-color: $warmWhite;
    }

    .rsc-ts-bot {
      align-items: flex-start;
      margin-bottom: 8px;
      padding: 0 16px;
      width: 100%;
      &:first-child {
        padding-top: 20px;
      }
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 20px;
      }
    }

    .rsc-ts-bubble {
      background-color: $warmWhite;
      font-family: $fontFamily;
      margin: 0;
      max-width: 84%;
    }

    .rsc-ts-bubble:has(.lineMessage) {
      max-width: 100%;
      background-color: transparent;
      width: 100%;
      margin: 0 auto;
    }

    .rsc-ts-image-container {
      display: none;
    }

    //user bubble
    .rsc-ts-user {
      margin-bottom: 12px;
      padding: 0 24px;
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 24px;
      }

      .rsc-ts-image-container {
        width: 0;
        margin: 0 0;
      }

      .rsc-ts-bubble {
        background-color: $sky;
      }
    }

    .rsc-input {
      font-family: $fontFamily;
    }

    .rsc-cs {
      box-shadow: none;
      justify-content: flex-end;
      margin: 12px 0 auto;
      padding: 0 24px;

      .rsc-ts-bubble,
      div {
        background-color: $sky;
        font-size: 14px;
      }
    }

    .rsc-cs:first-child {
      margin-top: auto;
    }
  }
}
