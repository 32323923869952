@use "source/theme" as *;

.root {
  @include flexColumn();
  gap: 24px;
  padding: 48px 32px;

  .country {
    // align-self: center;
    width: 300px;
  }

  .items {
    @include flexColumn();
    // align-self: center;
    max-width: 300px;
    width: 100%;

    .item {
      @include flexColumn();
      margin-bottom: 12px;
      width: 100%;

      .subdetail {
        font-size: $fontLabel;
        a {
          font-weight: normal;
          margin-inline-end: 8px;
        }
      }
    }
  }
}
