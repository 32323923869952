@use "source/theme" as *;

.content {
  @include flexRow($alignX: space-between, $alignY: stretch);

  .item {
    @include flexColumn(center, space-between);
    background-color: $warmWhite;
    border-radius: 16px;
    cursor: pointer;
    padding: 20px 40px;
    text-align: center;
    width: calc(100% / 3 - 16px);
    @include breakpoint-down(sm) {
      margin-bottom: 16px;
      width: 100%;
    }

    p {
      margin: 16px auto;
    }

    svg {
      fill: $darkBlue;
      height: 16px;
      width: 16px;
    }
  }
}
