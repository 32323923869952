@use "source/theme" as *;

.root {
  font-size: 16px;
  line-height: 20px;
  height: 36px;
  cursor: pointer;
  svg {
    width: 10px;
  }
}

.no_value {
  color: $disable;

  option {
    color: $mainText;
  }
}

.simple {
  border: none;
  background-color: transparent;
  color: $mainText;
  font-size: $fontLabel;
  font-weight: 700;
  height: unset;
  outline: none;
  width: 116px;
  padding-left: 0;
  padding-right: 4px;
  &:focus,
  &:hover {
    opacity: 9;
    border-color: unset;
    box-shadow: unset;
  }
}
