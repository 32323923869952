@use "source/theme" as *;

.root {
  background-color: $optumSecondary;
}

.content {
  align-items: center;
}

.cards {
  @include flexRow($alignY: stretch);
  gap: $gap1;
  margin-top: $gap2;
  width: 100%;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  
  .card {
    background-color: $white;
    border-radius: 12px;
    border: 1px solid $lightGray;
    padding: $gap1;
    gap: $gap1;
    display: flex;
    justify-content: space-between;
    transition: all 0.2s;
    font-weight: 700;

    color: $link;
    &:hover {
      color: $linkHover;
    }

    .icon {
      svg {
        fill: $link;
        transform: scale(1);
        transition: all 0.2s;
      }
    }

    &:hover {
      border-color: $link;
      transition: all 0.2s;
      svg {
        fill: $linkHover;
        transform: scale(1.2);
        transition: all 0.2s;
      }
    }
  }

}
