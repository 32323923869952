@use "source/theme" as *;

.header_root {
  background-color: $sky;

  .header {
    @include flexRow($alignY: center, $alignX: space-between);
    padding-bottom: 16px;
    padding-top: 16px;
    width: 100%;

    .side {
      @include flexRow($alignY: center, $wrap: nowrap);
      font-size: $fontLabel;

      svg {
        margin-inline-end: 20px;
        height: auto;
        width: 88px;
      }

      .linkButton,
      .reg {
        color: $mainText !important;
        margin-inline-start: 8px;
      }

      .reg {
        margin-inline-start: 24px;
      }
    }
  }
}

.banner_root {
  @include flexColumn();
  background-color: $sky;
  position: relative;
}

.banner {
  max-width: 1150px;
  width: 100%;

  .bg {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 124px;
    left: 0;
    @include breakpoint-down(sm) {
      height: 152px;
    }
  }

  .main {
    @include flexRow($alignY: center, $alignX: space-between);
    background-color: $sky;
    margin-bottom: 100px;
    width: 100%;
    @media screen and (max-width: 1024px) {
      margin-bottom: 60px;
    }
    @include breakpoint-down(sm) {
      flex-direction: column-reverse;
      // align-items: flex-start;
    }

    .text {
      @include flexColumn();
      max-width: 632px;
      width: 100%;
      @media screen and (max-width: 1024px) {
        max-width: 64%;
      }
      @include breakpoint-down(sm) {
        align-items: center;
        max-width: 100%;
        margin-bottom: 100px;
        text-align: center;
      }

      .title {
        font-size: 52px;
        line-height: 60px;
        @include breakpoint-down(md) {
          white-space: normal;
          font-size: 44px;
          line-height: 50px;
        }
      }

      .subtitle {
        color: $mainText;
        margin: 12px 0 20px;

        svg {
          width: 20px;
          border-color: $link;
        }
      }

      label {
        font-size: 12px;
        a {
          font-weight: 500;
          text-decoration: underline;
        }
      }

      .company {
        @include flexRow();
        width: 100%;
        margin-bottom: 12px;
        margin-top: 12px;

        .input {
          flex: 1;
          margin-inline-end: 12px;
          max-width: 400px;
          width: 100%;

          input {
            height: 44px;
          }
        }

        button {
          margin-bottom: 0;
          @include breakpoint-down(sm) {
            width: auto;
          }
          @include breakpoint-up(xs) {
            margin-top: 12px;
            width: 100%;
          }
        }
      }

      .healthsafe {
        font-size: $fontLabel;
      }
    }

    .bannerimg {
      position: absolute;
      right: 80px;
      top: 12px;
      max-width: 480px;
      width: 100%;
      z-index: 3;
      transition: all 0.2s ease-in-out;
      @media screen and (max-width: 1100px) {
        right: 16px;
      }
      @media screen and (max-width: 1024px) {
        width: 100%;
        max-width: 32%;
        bottom: 32px;
        top: unset;
      }
      @include breakpoint-down(md) {
        bottom: 80px;
        max-width: 40%;
      }
      @include breakpoint-down(sm) {
        position: static;
        max-width: 50%;
        margin-bottom: 20px;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  .bottom {
    @include flexRow($alignY: center);
    bottom: 32px;
    color: $darkBlue;
    position: absolute;
    z-index: 3;
    @media screen and (max-width: 1024px) {
      bottom: 16px;
    }
    @include breakpoint-down(sm) {
      flex-direction: column;
      align-self: center;
    }

    svg {
      height: 32px;
      margin-inline-end: 8px;
      margin-top: -6px;
      width: 32px;
    }

    .options {
      margin-top: 3px;
      font-size: 12px;
      margin-inline-start: 12px;
      @include breakpoint-down(sm) {
        margin-inline-start: 0;
      }

      svg {
        height: 16px;
        margin: 0;
        width: 16px;
      }
    }
  }
}

.information {
  @include flexColumn();
  width: 100%;
  max-width: 1100px;

  .items {
    @include flexRow($alignY: flex-start, $alignX: space-between);
    width: 100%;
    margin-top: 40px;

    .item {
      @include flexColumn($alignX: center);
      width: calc(100% / 3 - 20px);
      @include breakpoint-down(md) {
        width: calc(100% / 2 - 60px);
        margin-bottom: 40px;
      }
      @include breakpoint-down(sm) {
        width: 100%;
      }
    }

    svg {
      border-radius: 50%;
      height: auto;
      width: 100%;
      margin-bottom: 24px;
      max-width: 240px;
    }

    p {
      text-align: center;
      margin-top: 20px;
    }
  }
}
