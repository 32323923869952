@use "source/theme" as *;

.modal {
  color: $darkGray;
  width: 100%;
  max-width: 768px;
  padding: 40px;
  @include breakpoint-down(md) {
    padding: 56px 16px 32px;
  }

  .logo {
    height: 64px;
    margin-bottom: 20px;
    width: auto;
    @include breakpoint-down(md) {
      height: 48px;
    }
  }

  .intro {
    @include flexColumn($alignY: center);
    margin-bottom: 40px;
    text-align: center;

    h4 {
      color: $darkGray;
      font-size: $fontBody;
      font-weight: 700;
      margin-bottom: 8px;
      width: 100%;
      @include breakpoint-down(md) {
        font-size: $fontBody2;
      }
    }
  }

  .sectionContainer {
    width: 100%;
    @include flexColumn();
    gap: $gap2;
    margin-bottom: 60px;

    .section {
      width: 100%;
      overflow: hidden;
      border-radius: 8px;
      border: solid 1px $borderColor;
      .sectionContent {
        padding: $gap2;
        display: flex;
        justify-content: space-between;
        gap: $gap1;
        .sectionTextContainer {
          @include flexColumn();
          flex: 1;
          gap: 8px;
          .sectionTitle {
            color: $darkGray;
            font-size: $fontBody;
            font-weight: 700;
            width: 100%;
            @include breakpoint-down(md) {
              font-size: $fontBody2;
            }
          }
          .sectionText {
            font-size: $fontLabel;
          }
          .sectionInput {
            width: 100%;
          }
        }
        .avatarSelect {
        }
      }

      .sectionFooter {
        font-size: $fontLabel;
        border-top: solid 1px $borderColor;
        background-color: $lightGray;
        padding: $gap1 $gap2;
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 60px;
        button {
          font-size: $fontLabel;
          min-height: 30px;
          padding: 0px;
          margin-bottom: 0px;
          width: auto;
          min-width: 80px;
          font-weight: normal;
        }
      }
    }
  }

  .questions {
    @include flexColumn();
    width: 100%;

    h4 {
      color: $darkBlue;
      font-size: $fontBody;
      font-weight: 700;
    }

    .items {
      @include flexColumn();
      // border-bottom: solid 1px lightgrey;
      cursor: pointer;
      color: $darkBlue;
      font-weight: 700;
      // padding: 16px 0;
      width: 100%;

      .svgWrapper {
        padding-right: 15px;
      }
      svg {
        height: 14px;
        transition: transform 0.1s ease-out;
      }

      &.question_expand {
        svg {
          transform: rotate(90deg);
          transition: transform 0.1s ease-out;
        }
      }
    }
  }

  .cancel {
    cursor: pointer;
    margin-top: 20px;
    &:hover {
      transform: scale(1.05);
    }
  }
}

// in popover
.avatarPopover {
  width: auto;
  max-width: 400px;
  .avatarListContainer {
    padding: $gap2;
    .avatarList {
      // grid of 4 folumns
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: $gap1;

      .avatar {
        img {
          height: 40px;
          width: 40px;
        }
      }
    }
  }
}

.avatar {
  flex: 1;
  border-radius: 50%;
  overflow: hidden;
  border: solid 1px $borderColor;
  cursor: pointer;
  img {
    height: 60px;
    width: 60px;
    object-fit: cover;
  }
}