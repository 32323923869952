@use "source/theme" as *;

@mixin format($size: $h1-font-sizes) {
  @include font-size($size);
  color: $darkBlue;
  font-weight: 900;
}

.default {
  white-space: pre-wrap;
  width: 100%;
  @media screen and (max-width: 767px) {
    white-space: normal;
  }

  &.h1 {
    @include format();
    margin-bottom: 12px;
  }

  &.h2 {
    @include format($h2-font-sizes);
    margin-bottom: 12px;
  }

  &.h3 {
    @include format($h3-font-sizes);
  }

  &.h4 {
    @include format($h4-font-sizes);
  }

  &.h5 {
    @include format($h5-font-sizes);
  }
}

.uppercase {
  text-transform: uppercase;
}

.withMargin {
  margin: 12px 0;
}

.centered {
  text-align: center;
}

.label {
  white-space: pre-wrap;
  width: 100%;
  @media screen and (max-width: 1023px) {
    white-space: normal;
  }

  span {
    color: $link;
  }

  strong {
    font-weight: 900;
  }

  &.primary span {
    color: $darkGray;
  }
}
