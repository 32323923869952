@use "source/theme" as *;

.root {
  display: flex;
  flex-direction: column;
  padding: 60px 40px;
  width: 100%;
  @include breakpoint-down(sm) {
    padding: 60px 24px;
  }

  .header svg {
    height: 48px;
    margin-right: 8px;
  }

  .info {
    border-radius: 12px;
    box-shadow: 0 0px 15px rgba(0, 0, 0, 0.08);
    margin-top: 12px;
    padding: 24px;
    width: 100%;
  }

  .phone_wrapper,
  .website_wrapper {
    @include flexRow($alignY: center, $alignX: space-between);
    border-bottom: solid 1px lightgrey;
    padding: 16px 0;
    cursor: pointer;

    svg {
      height: 20px;
      fill: $darkBlue;
    }
  }

  .details_wrapper {
    padding-top: 16px;
    color: $mainText;

    p {
      margin: 16px 0;
    }

    ul {
      margin-bottom: 0;
    }
  }
}
