@use "source/theme" as *;

.items {
  @include flexRow($alignY: stretch);
  font-family: $fontFamily;
  width: 100%;
  gap: 20px;
}

.card {
  @include flexColumn();
  background-color: $warmWhite;
  border-radius: 8px;
  cursor: pointer;
  padding: 24px 20px 60px;
  width: 240px;
}

.smaller {
  margin-bottom: 20px;
  padding: 12px 20px 32px;
}
