@use "source/theme" as *;

.content {
  @include flexColumn(center, center);
  max-width: 800px;
  width: 100%;

  .article {
    width: 100%;

    .title {
      margin-bottom: 20px;
    }

    h4 {
      font-weight: 700;
      margin-top: 32px;
      margin-bottom: 8px;
    }

    p {
      margin-bottom: 20px;
    }

    ul {
      margin-bottom: 32px;

      li {
        margin-bottom: 12px;
      }
    }

    label {
      font-size: $fontLabel;
      margin-bottom: 8px;
    }
  }

  .item {
    @include flexColumn();
    border-bottom: 1px solid $lightGray;
    padding: 32px 0 40px;
    width: 100%;

    .title {
      cursor: pointer;
    }

    p {
      font-size: $fontLabel;
      margin-top: 20px;
    }
  }
}
