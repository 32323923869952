@use "source/theme" as *;

.chatbot_container {
  align-self: flex-end;
  max-height: 84vh;
  max-width: 1272px;
}

.chatbot_modal {
  border-radius: 20px 20px 0 0;
  margin: auto 0 0;
  max-height: 84vh;
  max-width: 1272px;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
  height: 100%;
  @include breakpoint-down(sm) {
    border-radius: 0;
    margin: 0;
    max-width: 100%;
  }

  .divider {
    background-color: $gray;
    border-radius: 12px;
    height: 6px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 16px;
    width: 48px;
    z-index: 2;
  }
}

.content {
  height: 100%;
  max-width: 1272px;
  position: relative;
  width: 100%;
  @include breakpoint-down(md) {
    max-height: unset;
  }
  @include breakpoint-down(sm) {
    max-width: 100%;
  }
}

.chats {
  @include flexColumn();
  height: 100%;
  width: 100%;

  .top {
    @include flexRow(space-between, flex-end, nowrap);
    background-color: $warmWhite;
    border-bottom: 1px rgba(#999, 0.2) solid;
    padding: 52px 32px 20px;
    width: 100%;

    button {
      @include flexRow($alignX: center, $wrap: nowrap);
      border: 0.5px solid rgba(#002677, 0.4);
      font-size: $fontLabel;
      height: 32px;
      line-height: normal;
      min-height: unset;
      min-width: unset;
      padding: 4px 12px;

      svg {
        margin-inline-start: 4px;
        path {
          fill: $link;
        }
      }
    }
  }

  .bottom {
    @include flexRow($wrap: nowrap);
    flex: 1;
    height: 100%;
    width: 100%;
  }

  .conversations {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;

    .visible {
      position: absolute;
      visibility: visible;
      z-index: 3;
    }

    .hidden {
      position: absolute;
      visibility: hidden;
      z-index: 2;
    }
  }

  .sessions {
    @include flexColumn();
    border-right: 1px rgba(#999, 0.2) solid;
    background-color: $lightGray;
    height: 100%;
    max-width: 314px;
    width: 100%;

    .item {
      @include flexRow(space-between, flex-start, nowrap);
      border-bottom: 1px rgba(#999, 0.2) solid;
      height: 72px;
      padding: 16px;
      width: 100%;
      &.active {
        background-color: $gray;
      }

      .item_left {
        @include flexColumn();
        cursor: pointer;
        width: 100%;

        .item_top {
          @include flexRow($alignX: space-between, $wrap: nowrap);
          font-size: $fontLabel;
          line-height: 20px;
          width: 100%;

          .item_title {
            @include flexRow($alignY: center, $wrap: nowrap);
          }

          label {
            margin-inline-end: 12px;
            font-size: 12px;
            line-height: 16px;
          }
        }

        .icon {
          border: 1px dashed $darkGray;
          border-radius: 50%;
          height: 12px;
          margin-inline-end: 8px;
          width: 12px;
        }

        p {
          margin-inline-end: 12px;
          font-size: 12px;
          line-height: 16px;
          margin-top: 4px;
          color: rgba(#4b4d4f, 0.8);
          width: 100%;
        }
      }

      .item_right {
        line-height: 14px;
        svg {
          height: 12px;
          width: auto;
        }
      }
    }
  }
}

.options {
  @include flexColumn();
  position: relative;
  width: 100%;

  .svg svg {
    cursor: pointer;
    fill: $link;
    height: 10px;
    width: auto;
  }

  .modal {
    @include flexColumn();
    background-color: $warmWhite;
    border-radius: 12px;
    border: 1px rgba(#999, 0.2) solid;
    color: $darkGray;
    font-weight: 500;
    line-height: $fontLabel;
    padding-bottom: 8px;
    padding-top: 8px;
    position: absolute;
    right: 0px;
    top: 28px;
    width: 220px;
    z-index: 4;

    label {
      font-size: 12px;
      opacity: 0.8;
      padding: 4px 20px;
    }

    .action {
      @include flexRow($alignY: center, $wrap: nowrap);
      cursor: pointer;
      padding: 8px 20px;
      width: 100%;
      &:hover,
      &:active {
        background-color: $gray;
      }

      svg {
        width: 12px;
        height: 12px;
        margin-inline-end: 8px;
      }
    }

    .line {
      border-top: 1px solid $gray;
      margin: 8px 0;
      width: 100%;
    }

    .delete {
      color: $error;
      &:hover,
      &:active {
        background-color: rgba(#c93a29, 0.1);
      }

      svg {
        fill: $error;
      }
    }
  }

  .main {
    right: -16px;
    top: 36px;
  }
}

.chatbot {
  max-height: calc(84vh - 113px);
  height: 100%;
  width: 100%;
  ::-webkit-scrollbar {
    display: none;
  }

  :global {
    .rsc-container {
      background-color: var(--palette-white);
      background-image: radial-gradient(rgb(223, 227, 230) 1px, transparent 0);
      background-size: 24px 24px;
      box-sizing: border-box;
      box-shadow: none;
      height: 100%;
      width: 100%;
    }

    .rsc-content {
      display: flex;
      flex-flow: column nowrap;
      height: 100%;
      margin-top: 0;
      padding-top: 0;
      overflow-y: auto;
      width: 100%;
      min-height: 300px;
      margin-top: 0;
      padding-bottom: 12px;
    }

    .rsc-ts-bot {
      align-items: flex-start;
      margin-bottom: 8px;
      padding: 0 20px;
      width: 100%;
      &:first-child {
        padding-top: 20px;
      }
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 20px;
      }
    }

    .rsc-ts-bubble {
      background-color: $warmWhite;
      font-family: $fontFamily;
      margin: 0;
      max-width: 80%;
    }

    .rsc-ts-image-container {
      display: none;
    }

    //eval questions
    .eval-root {
      position: relative;
    }

    .eval-bubble {
      background-color: #ffecbc; //theme
      position: absolute;
      top: -12px;
      left: -12px;
      right: -12px;
      padding: 12px;
    }

    //options
    .rsc-os {
      background-color: $inputBG;
      border-top: 1px rgba(#999, 0.2) solid;
      position: sticky;
      margin-top: auto;
      padding: 16px 16px 12px;
      width: 100%;
      margin-bottom: -12px;
    }

    .rsc-os-options {
      @include flexRow($alignX: flex-end);
      margin: 0;
      padding: 0;
    }

    .rsc-os-option {
      margin-inline-end: 4px;
    }

    .rsc-os-option-element {
      background-color: $sky; //theme
      border: 1px solid rgba(#002677, 0.5); //theme
      color: $link;
      cursor: pointer;
      padding: 4px 6px;
      margin-bottom: 4px;
      font-family: $fontFamily;
      border-radius: 8px;
    }

    //user bubble
    .rsc-ts-user {
      margin-bottom: 12px;
      padding: 0 24px;
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 24px;
      }

      .rsc-ts-image-container {
        width: 0;
        margin: 0 0;
      }

      .rsc-ts-bubble {
        background-color: $sky;
      }
    }

    .rsc-cs {
      box-shadow: none;
      justify-content: flex-end;
      margin: 12px 0 auto;
      padding: 0 24px;

      .rsc-ts-bubble {
        background-color: $sky;
        font-size: 14px;
      }
    }

    .rsc-cs:first-child {
      margin-top: auto;
    }
  }
}
