//Color Palette
$optumOrange: var(--palette-primary, #ff612b);
$optumOrangeHover: var(--palette-primary-hover, #eb4e1a);
$optumSecondary: var(--palette-secondary, #d9f6fa);
$sky: var(--palette-sky, #d9f6fa);
$white: var(--palette-white, #ffffff);
$warmWhite: var(--palette-warm-white, #faf8f2);
$darkBlue: var(--palette-dark-blue, #002677);
$darkGray: var(--palette-dark-gray, #4b4d4f);
$gray: var(--palette-gray, #e5e5e6);
$lightGray: var(--light-gray, #f5f5f5);
$inputBG: var(--palette-input-bg, #f9fafb);
$disable: var(--palette-disable, #999);
$link: var(--palette-link, #0c55b8);
$linkHover: var(--palette-link-hover, #0c54b8a2);
$mainText: var(--palette-main-text, #4b4d4f);
$error: var(--palette-error, #c93a29);
$success: var(--palette-success, #4caf50);

$alertColor: var(--palette-alert-color, $darkGray);
$alertBG: var(--palette-alert-bg, $white);

:root {
  --palette-primary: #ff612b;
  --palette-primary-hover: #eb4e1a;
  --palette-secondary: #d9f6fa;
  --palette-white: #ffffff;
  --palette-sky: #d9f6fa;
  --palette-warm-white: #faf8f2;
  --palette-dark-blue: #002677;
  --palette-dark-gray: #4b4d4f;
  --palette-input-bg: #f9fafb;
}

//Defaults
$borderColor: #e0e0e0;
$borderColor2: #CBCCCD;
$buttonBGColor: var(--palette-button-bg, $darkBlue);
$buttonColor: var(--palette-button-text, $white);
$buttonHover: var(--palette-button-bg-hover, $linkHover);
$footerBGColor: $warmWhite;
$shadow: rgba(0, 0, 0, 35%);

$benefitsBG: $lightGray;
$healthJourneyBG: $lightGray;
$bannerSuptitleText: $optumOrange;
$chatbotHeaderBG: $darkBlue;
