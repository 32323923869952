@use "source/theme" as *;

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.login_wrapper {
  animation: fadeIn 0.5s;
  width: 100%;
  z-index: $layerModal;
  @include breakpoint-down(sm) {
    margin: 0 auto;
    width: 100%;
  }
}

.login_box {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 20%);
  padding: 20px 0 0px 0;
  @include breakpoint-down(sm) {
    box-shadow: none;
    margin-bottom: 0;
  }
}

.logo {
  @include flexColumn(center, center);
  padding: 24px 0;

  img,
  svg {
    height: var(--logo-height);
    max-width: 90%;

    @include breakpoint-down(sm) {
      max-width: 40%;
      height: auto;
    }
  }
}
