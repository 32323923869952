@use "source/theme" as *;

.login_container {
  padding: 0;
  width: 100%;
}

.rootbanner {
  background-color: $sky;
}

.banner {
  @include flexRow($alignX: space-between);
  align-items: center;
  padding-top: 40px;
  padding-bottom: 60px;
  @include breakpoint-down(md) {
    flex-direction: column-reverse;
  }

  .text {
    @include flexColumn();
    flex: 1;
    max-width: 652px;
    width: 60%;
    @include breakpoint-down(xl) {
      width: 70%;
    }
    @include breakpoint-down(md) {
      align-items: center;
      text-align: center;
      max-width: unset;
      width: 100%;
    }
  }

  svg {
    max-width: 452px;
    width: 40%;
    height: auto;
    margin-inline-start: 20px;
    transition: all 0.2s ease-in-out;
    @include breakpoint-down(xl) {
      width: 30%;
    }
    @include breakpoint-down(md) {
      width: auto;
      height: 200px;
    }
  }

  .subtitle {
    color: $darkBlue;
    font-weight: 500;
    margin: 12px 0 40px;
  }

  .bannerButtons {
    @include flexRow($alignX: center);
    @include breakpoint-down(sm) {
      width: 100%;
    }
    button:first-child {
      margin-inline-end: 16px;
      @include breakpoint-down(sm) {
        margin-inline-end: 0;
      }
    }
  }
}
