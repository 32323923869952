@use "source/theme" as *;

.image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.modal {
  max-width: 640px;
  padding: 32px;
  @include breakpoint-down(sm) {
    padding: 30px 30px 60px 30px;
    margin: 0;
    width: 100%;
    border-radius: 0px;
    max-width: 100%;
  }

  .tutorial_item_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px 0px 32px 0px;
    background-color: white;

    .tutorial_item {
      max-width: 463px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      img,
      svg {
        width: 100%;
      }

      p {
        font-size: 16px;
      }
    }
  }
}

.tu_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  width: 100%;
  .title {
    font-size: 30px;
    font-weight: bold;
    color: $darkBlue;
    padding-bottom: 24px;
    text-align: center;
    line-height: 2.4rem;
  }

  .text {
    padding-top: 48px;
    font-size: 16px;
    color: $darkBlue;
  }
  ul {
    // padding: 0.7rem 1.4rem 0 1rem;
    padding: 0px;
    margin: 1rem auto 0.8rem auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 300px;
    width: 100%;
    li {
      text-align: left;
      // color: $mainText;
      &::marker {
        color: #ff612b;
      }
    }
    padding: 0px 10px;
  }
  .button_container {
    padding-top: 48px;
  }
}
.tu1 {
  .text {
    padding-top: 0px;
  }
}

.skip {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 12px 24px 0px;
  cursor: pointer;
  color: $link;
  &:hover {
    color: $linkHover;
  }
}

.banner {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.banner :global {
  .slick-slide {
    pointer-events: none;
  }

  .slick-current {
    pointer-events: auto !important;
  }

  .slick-dots {
    margin: 0 auto;
    position: static;
    text-align: center;
    // transform: translateY(-45px);

    @include breakpoint-up(md) {
      // @include pageCenter();
      // text-align: right;
      // transform: translateY(-95px);
    }

    .slick-active {
      background-color: $darkBlue;
      outline: none;
    }
  }

  .slick-track {
    display: flex;
  }
}

.button {
  @include button();
  padding: 0px 35px;
}
