@use "source/theme" as *;

.root {
  background-color: $warmWhite;
  border-bottom: 1px solid $gray;
  box-shadow: 0 1px 6px 0 rgb(0 0 0 / 3%);
  top: 0;
  z-index: 1;
  @include breakpoint-down(md) {
    position: fixed;
  }

  .content {
    flex-direction: column;
    font-size: $fontLabel;
    padding-bottom: 0;
    padding-top: 24px;
    width: 100%;
    @include breakpoint-down(md) {
      padding-bottom: 0;
      padding-top: 0;
    }
  }

  .divider {
    background-color: $gray;
    height: 20px;
    margin: 0 12px;
    width: 2px;
    @include breakpoint-down(md) {
      display: none;
    }
  }

  .header {
    @include flexRow($alignX: space-between);
    position: relative;
    width: 100%;
    @include breakpoint-down(md) {
      align-items: center;
      height: 48px;
      justify-content: center;
      padding: 0;
    }

    .logo svg {
      height: 52px;
      padding-top: 8px;
      @include breakpoint-down(md) {
        height: 30px;
        padding-top: 4px;
      }
    }

    .menu {
      bottom: 0;
      display: none;
      left: 12px;
      position: absolute;
      top: 0;

      svg {
        fill: $darkGray;
        height: auto;
        margin: auto 0;
        width: 16px;
      }
      @include breakpoint-down(md) {
        display: flex;
        left: 4px;
      }
    }

    .helplinks {
      @include flexRow();
      @include breakpoint-down(md) {
        display: none;
      }
      z-index: 4;
    }
  }

  .sidebar {
    @include flexColumn();
    width: 100%;
  }

  .dock {
    @include flexRow($alignY: center, $alignX: center);
    align-self: center;
    background-color: $lightGray;
    border-radius: 52px;
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 12%);
    max-width: 720px;
    opacity: 0;
    position: fixed;
    top: 40px;
    width: 100%;
    z-index: 3;
    // visibility: hidden;
    transition: opacity 0.5s ease-in-out;
    @include slideDown();
    @include breakpoint-down(md) {
      display: none;
    }

    &.visible {
      @include slideUp();
      opacity: 1;
    }

    .start {
      font-size: $fontLabel;
      margin-bottom: 0;
      min-height: 32px;
      min-width: unset;
      border-radius: 32px;
      padding: 0 12px;
      margin-inline-end: 8px;
    }

    .settings {
      font-size: $fontLabel;
      border: 0.5px solid $gray;
      border-radius: 50%;
      height: 32px;
      width: 32px;
    }
  }

  .linkButton {
    @include flexRow(flex-start, flex-end, nowrap);
    color: $link;
    cursor: pointer;
    font-weight: 700;
    white-space: nowrap;
    @include breakpoint-down(md) {
      align-items: center;
      box-shadow: inset 0 1px 0 #e5e5e6;
      height: 56px;
      padding: 0 24px;
      width: 100%;
    }
    &:hover {
      color: $linkHover;
      svg {
        filter: brightness(1.2);
      }
    }

    svg {
      fill: $link;
      height: 20px;
      width: 20px;
    }
  }

  .header_bottom {
    @include flexRow($alignX: space-between, $alignY: center);
    padding-top: 8px;
    width: 100%;
    @include breakpoint-down(md) {
      display: none;
    }
  }

  .items {
    @include flexRow(flex-start, center, nowrap);
    padding-bottom: 16px;

    .button {
      @include flexRow(center, center, nowrap);
      font-size: $fontLabel;
      height: 32px;
      margin: 0;
      margin-inline-start: 12px;
      padding: 4px 16px;
      svg {
        height: 24px;
        margin-inline-end: 2px;
        width: 24px;
      }
    }

    @include breakpoint-down(md) {
      align-items: center;
      box-shadow: inset 0 1px 0 #e5e5e6;
      flex-wrap: wrap;
      padding: 16px 24px;
      width: 100%;
    }

    @include breakpoint-down(sm) {
      flex-direction: column;
      .button {
        margin-top: 12px;
        margin-inline-start: 0;
      }
    }
  }
}

.select {
  font-weight: 700;
  color: $link;
}