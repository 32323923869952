@use "colors" as *;

//Mixins
// Breakpoints
@mixin breakpoint-up($point) {
  @if $point == xl {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $point == lg {
    @media (min-width: 992px) {
      @content;
    }
  } @else if $point == md {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $point == sm {
    @media (min-width: 576px) {
      @content;
    }
  } @else if $point == xs {
    @media (max-width: 576px) {
      @content;
    }
  }
}

// Breakpoints
@mixin breakpoint-down($point) {
  @if $point == xl {
    @media (max-width: 1200px) {
      @content;
    }
  } @else if $point == lg {
    @media (max-width: 992px) {
      @content;
    }
  } @else if $point == md {
    @media (max-width: 768px) {
      @content;
    }
  } @else if $point == sm {
    @media (max-width: 576px) {
      @content;
    }
  } @else if $point == xs {
    @media (min-width: 576px) {
      @content;
    }
  }
}

@mixin flex(
  $direction: column,
  $justifyContent: flex-start,
  $alignItems: flex-start
) {
  align-items: $alignItems;
  box-sizing: border-box;
  display: flex;
  flex-direction: $direction;
  justify-content: $justifyContent;
}

@mixin flexRow($alignX: flex-start, $alignY: flex-start, $wrap: wrap) {
  @include flex(
    $direction: row,
    $justifyContent: $alignX,
    $alignItems: $alignY
  );
  flex-wrap: $wrap;
}

@mixin flexColumn($alignX: flex-start, $alignY: flex-start) {
  @include flex($justifyContent: $alignY, $alignItems: $alignX);
}

@mixin pageCenter() {
  @include flexColumn(center, center);
  margin: 12px auto;
  max-width: 1292px;
  padding: 0 20px;
  text-align: center;
}

@mixin textCenter() {
  @include flexColumn(center, center);
  max-width: 950px;
  padding-bottom: 20px;
  padding-top: 20px;
  text-align: center;
}

@mixin pageLayout($size: 1300px) {
  max-width: $size;
  margin: auto;
  padding-right: 16px;
  padding-left: 16px;
  box-sizing: border-box;
  @media screen and (max-width: 1440px) {
    max-width: $size - 150px;
  }
  @media screen and (max-width: 768px) {
    max-width: $size - 300px;
  }
}

@mixin fade() {
  animation: fadeIn 0.5s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

@mixin slideUp() {
  animation: slide-up 0.5s forwards;
  @keyframes slide-up {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0%);
    }
  }
}

@mixin slideDown() {
  animation: slide-down 0.5s forwards;
  @keyframes slide-down {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(-100%);
    }
  }
}

@mixin font-size($fs-map) {
  @each $fs-breakpoint, $fs-font-size in $fs-map {
    @if $fs-breakpoint == null {
      font-size: $fs-font-size;
      line-height: $fs-font-size + 8px;
    } @else {
      @media screen and (max-width: $fs-breakpoint) {
        font-size: $fs-font-size;
        line-height: $fs-font-size + 8px;
      }
    }
  }
}

@mixin imageDiv($size: cover, $width: 300px, $height: 100px) {
  background-position: center;
  background-repeat: no-repeat;
  background-size: $size;
  border-radius: 4px;
  height: $height;
  width: $width;
}

@mixin button() {
  background-color: $buttonBGColor;
  border-radius: 28px;
  border: none;
  color: $buttonColor;
  cursor: pointer;
  min-height: 44px;
  font-weight: 700;
  min-width: 120px;
  outline: none;
  transition: all ease-in-out 0.2s;
  // white-space: nowrap;

  &:hover,
  &:focus {
    background-color: $buttonHover;
  }

  &:disabled {
    background-color: $darkGray;
    cursor: default;
  }
}

@mixin buttonWhite($color: $darkBlue, $hoverColor: $linkHover) {
  @include button();
  background-color: $white;
  border: 2px solid $color;
  color: $color;

  &:hover,
  &:focus {
    background-color: $white;
    color: $hoverColor;
  }
}

@mixin buttonLink() {
  @include buttonWhite();
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}