@use "source/theme" as *;

$bannerHeight: 180px;
$bannerHeightHome: 490px;

.banner :global {
  height: $bannerHeight;

  background: var(--palette-secondary);

  .slick-slide {
    pointer-events: none;
  }

  .slick-current {
    pointer-events: auto !important;
  }

  .slick-list {
    height: $bannerHeight;
  }

  .slick-dots {
    position: static;
    text-align: center;
    transform: translateY(-50px);
    margin: auto;
    padding: 16px 10px;
    box-sizing: border-box;
    box-sizing: border-box;

    li {
      margin-bottom: 1px;
      margin-top: 1px;
      background-color: $darkGray;
      opacity: 0.4;
      border-radius: 20px;
      height: 10px;
      width: 32px;
    }

    .slick-active {
      opacity: 1;
    }

    box-sizing: border-box;
    // margin-top: -16px;
    // padding-right: 25px;
  }
}

.root {
  @include flex();
  background-color: $gray;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  align-items: center;
  height: $bannerHeight;
  transition: background-image 0.2s ease-in-out;
  width: 100%;
  // @media screen and (max-width: 1440px) {
  //   height: 212px;
  // }
}

.withContent {
  @media screen and (max-width: 550px) {
    background: #00797a !important;
    height: auto;
  }
}


.bannerHome :global{
  @include breakpoint-up(lg) {
    height: $bannerHeightHome;
    .slick-list {
      height: $bannerHeightHome;
    }
  }
}

.rootHome {
  @include breakpoint-up(lg) {
    justify-content: unset;
    height: $bannerHeightHome;
    // margin-top: 10%;

    .content {
      position:absolute;
      top:25%;
    }
  }
}


.contentContainer {
  padding: 16px;
}

.content.active {
  border: 4px solid $borderColor;
  // firm shadow to emulate thickness
  box-shadow: 
  6px 6px 0 -2px $borderColor,      /* Bottom-right corner, smaller */
}

.content {
  box-sizing: border-box;
  padding: 16px 12px;
  margin:unset;
  width: 100%;

  border: 1px solid $lightGray;
  border-radius: 12px;

  .main {
    @include flexColumn();
    border-radius: 4px;
    text-align: left;

    align-self: center;

    // background-color: rgba(255, 255, 255, 0.85);
    // box-shadow: 0 0 .5rem 0 rgba(0, 0, 0, .03); 

    background-color: $white;
    // backdrop-filter: blur(10px);
    
    @include breakpoint-up(xs) {
      margin: 0;
    }   

    @include breakpoint-up(md) {
      max-width: 50%;
      align-items: flex-start;
      text-align: left;
      margin: 0;
    }

    @include breakpoint-up(lg) {
      max-width: 50%;
      margin: 0;
    }

    .text {
      margin-bottom: 20px;
      white-space: pre-line;
    }

    .button {
     
    }

  }
}